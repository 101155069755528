import type {ReactElement} from 'react';
import React from 'react';
import {Text, TextSize} from '@Components/text';
import type {ThumbnailData} from '@Components/thumbnail';
import {Thumbnail, THUMBNAIL_TYPE} from '@Components/thumbnail';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import {noop} from '@Utils/general.util';
import {PulsatingDot} from '@Components/poster-editor/components/pulsating-dot';
import styles from './controlled-list-item-type-2.module.scss';

export interface ControlledListItemType2Props extends BaseControlledListItem {
  thumbnail?: ThumbnailData;
  hasPulsatingDot?: boolean;
  subText: string;
  isDisabled?: boolean;
  disableHoverTransitions?: boolean;
  textClasses?: string;

  onClick?(id?: string): void;
}

export function ControlledListItemType2({
  className = '',
  id = '',
  isSelected = false,
  isDisabled = false,
  hasPulsatingDot = false,
  onClick = noop,
  disableHoverTransitions = false,
  textClasses = '',
  ...props
}: ControlledListItemType2Props): ReactElement {
  const onClickHandler = (): void => {
    if (onClick && !isDisabled) {
      onClick(id);
    }
  };

  const displayThumbnail = (): ReactElement | null => {
    if (props.thumbnail) {
      return (
        <div className={styles.thumbnailContainer}>
          {hasPulsatingDot ? <PulsatingDot containerClassname={styles.pulsePosition} /> : null}
          <Thumbnail className={`${props.thumbnail.type !== THUMBNAIL_TYPE.ICON ? styles.image : ''}`} data={props.thumbnail} />
        </div>
      );
    }
    return null;
  };

  const display = (): ReactElement => {
    return (
      <li
        key={id}
        onClick={() => {
          onClickHandler();
        }}
        className={`
          spacing-p-1 
          flexbox 
          flex-items-center 
          flex-justify-start 
          ${className} 
          ${isSelected ? styles.selected : ''} 
          ${isDisabled ? styles.disabledList : ''}
          ${styles.listItemContainer} 
          ${!disableHoverTransitions ? 'hover-transition-all' : ''} 
        `}
      >
        {displayThumbnail()}
        <div className={`${styles.textBox} flex-v-row`}>
          <Text
            val={props.text}
            className={`${textClasses} ${isDisabled ? styles.disableText : ''} ${isSelected ? 'content-primary' : 'content-body'}`}
            size={TextSize.XSMALL}
            bold
          />
          {props.subText ? <Text val={props.subText} className={isSelected ? 'content-primary' : 'content-sub-text'} size={TextSize.XXSMALL} /> : null}
        </div>
      </li>
    );
  };

  return <>{display()}</>;
}
