import type {ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {BaseControlledListItem, ControlledListItem3ActionProps} from '@Components/controlled-list/controlled-list.types';
import {ControlledListItemSize} from '@Components/controlled-list/controlled-list.types';
import Divider from '@Components/divider';
import type {ThumbnailData} from '@Components/thumbnail';
import {Thumbnail, THUMBNAIL_TYPE} from '@Components/thumbnail';
import styles from './controlled-list-item-type-3.module.scss';

export interface ControlledListItemType3Props extends BaseControlledListItemType3 {
  subItems?: Array<BaseControlledListItemType3>;
  scrollIntoViewIfSelected?: boolean;
}

export interface BaseControlledListItemType3 extends BaseControlledListItem {
  icon?: string;
  subText?: string;
  actions?: Array<ControlledListItem3ActionProps>;
  containsDangerouslySetText?: boolean;
  showSelectionCheckmark?: boolean;
  thumbnail?: ThumbnailData;
  thumbnailClassName?: string;
  isItemUnmargined?: boolean;

  onClickSubItem?(id: string, currentSelectionState?: boolean): void;
}

export function ControlledListItemType3({
  id = '',
  className = '',
  isSelected = false,
  icon = '',
  subText = '',
  subItems = [],
  onClick = $.noop,
  onClickSubItem = $.noop,
  containsDangerouslySetText = false,
  showSelectionCheckmark = true,
  listSize = ControlledListItemSize.MEDIUM,
  scrollIntoViewIfSelected = false,
  ...props
}: ControlledListItemType3Props) {
  const ref = useRef<HTMLLIElement>(null);

  useEffect((): void => {
    if (ref && ref.current && scrollIntoViewIfSelected && isSelected) {
      ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [ref]);

  const getClassnameForSubItem = (isSubitemSelected?: boolean): string => {
    return `${styles.subItemContainer} ${
      isSubitemSelected ? styles.selectedSubItemContainer : ''
    } radius-4 spacing-p-0 spacing-m-t-2 spacing-m-l-6 flex-justify-between flex-items-center`;
  };

  const showSubItems = () => {
    if (isSelected && subItems?.length > 0) {
      return (
        <ul className={`${styles.subItems} spacing-m-0 spacing-m-b-1`}>
          {subItems.map((record) => {
            return (
              <li
                onClick={() => {
                  onClickSubItem(record.id, record.isSelected ? record.isSelected : false);
                }}
                key={record.id}
                className={getClassnameForSubItem(record.isSelected)}
              >
                <Text
                  className={`spacing-m-l-3 ${record.isSelected ? 'content-primary' : 'content-sub-text'}`}
                  val={record.text}
                  size={TextSize.XSMALL}
                  bold={record.isSelected}
                />
              </li>
            );
          })}
          <Divider additionalClasses="spacing-m-t-2" />
        </ul>
      );
    }
    return null;
  };

  const getSelectedCheck = () => {
    return (
      <div className="flexbox spacing-m-r-3">
        <Icon className={styles.icon} icon="icon-check" size={IconSize.SIZE_ICON_20} />
      </div>
    );
  };

  const getCTAForListItemAction = (action: ControlledListItem3ActionProps, index: number) => {
    const {containerClasses, ...iconProps} = action;
    return (
      <div key={index} className={containerClasses}>
        <Icon {...iconProps} />
      </div>
    );
  };

  const displayThumbnail = (): ReactElement | null => {
    if (props.thumbnail) {
      return <Thumbnail className={`${props.thumbnail.type !== THUMBNAIL_TYPE.ICON ? styles.image : ''} ${props.thumbnailClassName} spacing-m-r-2`} data={props.thumbnail} />;
    }

    return null;
  };

  const getSpacingClasses = (): string => {
    if (listSize === ControlledListItemSize.SMALL) {
      return 'spacing-p-l-3 spacing-p-r-3 spacing-p-t-2 spacing-p-b-2';
    }

    if (props.thumbnail !== undefined) {
      return 'spacing-p-2';
    }

    return 'spacing-p-3';
  };

  const showListItem = () => {
    return (
      <li ref={ref} className={`${styles.container} flex-column-align-center ${props.isItemUnmargined ? '' : 'spacing-m-b-2'} spacing-p-0`}>
        <div className={`${styles.contentContainer} flex-row-align-center`}>
          <div
            onClick={() => {
              return onClick(id);
            }}
            className={`${styles.listItemContainer} spacing-p-0 flex-justify-between flex-items-center ${className} ${isSelected ? styles.selectedListItemContainer : ''} radius-4`}
          >
            <div className={`${styles.textContainer} ${getSpacingClasses()}`}>
              <div className="flex-row-align-center">
                {displayThumbnail()}
                <div>
                  <Text
                    className={`-truncated-text _full-width ${isSelected ? 'content-primary' : 'content-body'}`}
                    val={props.text}
                    size={TextSize.XSMALL}
                    bold={isSelected}
                    {...(containsDangerouslySetText && {dangerouslySetInnerHTML: true})}
                  />
                </div>
              </div>
              {subText ? <Text className={`${isSelected ? 'content-primary' : 'content-sub-text'}`} val={subText} size={TextSize.XXSMALL} bold={isSelected} /> : null}
            </div>
            {showSelectionCheckmark && isSelected ? getSelectedCheck() : null}
          </div>
          {typeof props.actions !== 'undefined' ? props.actions.map(getCTAForListItemAction) : null}
        </div>
        {showSubItems()}
      </li>
    );
  };

  return <>{showListItem()}</>;
}
